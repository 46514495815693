.collection-interior {
    background-color: $offwhite;
    padding: 50px 75px 150px;
    @include media-breakpoint-down(sm) {
        padding: 50px 0 150px;
    }
    .collection-name {
        padding: 50px 0 50px 0;
    }
    .collection-interior-carousel {
        .carousel-cell {
            margin-left: 1.5%;
            margin-right: 1.5%;
            @include media-breakpoint-down(sm) {
                margin: 0;
            }
        }
        img {
            width: 500px;
        }
        .flickity-slider {
            display: flex;
            align-items: center;
          }
        // .flickity-viewport {
        //     transition: height 0.5s;
        // }
        .flickity-prev-next-button {
            bottom: -100px;
            top: unset;
            &.next {
                right: 43%;
                @media (max-width: 1300px) { 
                    right: 20%;
                }
            }
            &.previous {
                left: 43%;
                @media (max-width: 1300px) { 
                    left: 20%;
                }
            }
        }
        .flickity-button-icon {
            // width: 500px;
            height: 50px;
            width: 50px;
            fill: $dark-grey;
        }
    }
}