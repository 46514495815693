.single-content {
    padding: 50px 100px;
    //height: 2050px;
    @include media-breakpoint-down(sm) {
        padding: 50px 0px;
    }
}

.page-description {
    padding: 50px 10px;
    h1 {
        padding: 40px 20px 20px 40px;
    }
}

.grid {
    padding: 0 50px;
    @include media-breakpoint-down(xs) {
        padding: 0 15px;
    }
}