form {
    font-family: $heading-font;
    input,textarea {
        border: 1px solid $dark-grey;
    }
    button {
        background-color: $dark-grey;
        padding: 20px 80px;
        color: white;
        font-size: 1.5rem;
    }
}