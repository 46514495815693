.footer {
    .newsletter {
        padding: 100px 0 50px 0;
        color: white;
    }
    background-color: $dark-grey;
    ul.social {
        padding: 50px 0;
        margin: 0;
        list-style: none;
        li {
            display: inline-block;
            border: 1px solid #999;
            margin: 0 15px;
            background-color: $dark-grey;
            @media (max-width: 340px) { 
                margin: 5px 15px;
            }
            a {
                height: 40px;
                width: 40px;
                background: $dark-grey;
                &:hover {
                    text-decoration: none;
                }
                i {
                    font-size: 1.5rem;
                    color: #c5c6c4;
                }
            }
        }
        hr {
            background-color: #999;
            margin-top: -21px;
            @media (max-width: 332px) { 
                display: none;
            }
        }
    }
    .quicklinks {
        li {
            list-style: none;
            color: $body-font-color;
            a {
                color: white;
            }
        }
        .quicklinks-title {
            font-family: $heading-font;
            color: white;
            margin-bottom: 20px;
            font-size: 1.7rem;
        }
        .children {
            font-family: $body-font;
        }
    }
    .logo {
        padding: 50px 0;
        img {
            background-color: black;
            padding: 20px;
            width: 180px;
        }
    }
    .copyright {
        display: flex;
        flex-direction: column;
        background-color: black;
        color: white;
        padding: 50px 20px;
        a {
            color: white;
            text-decoration: underline;
        }
        @media (max-width: 768px) { 
            text-align: center;
        }
    }
}