.breadcrumbs {
    top: 600px;
    @include media-breakpoint-down(sm) {
        top: 560px;
    }
    padding: 0;
    a {
        color: $dark-grey;
    }
    .inactive {
        color: rgba($color: $dark-grey, $alpha: 1)
    }
}