.image-contained {
    width: 100%;
    min-height: 1px;
    padding: 50px 0;
}

.image-w-divider {
    .divider {
        height: 34px; 
        width: 190px;
    }
    img {
        &:nth-child(3) {
            transform: scale(-1, -1);
        }
    }
}
