.blockQuote {
    //border-left: 4px solid black;
    margin: 30px 0 30px 30px;
    h1 {
        span {
            background-color: #eaeaea;
            line-height: 1.5;
            transition: background-color .5s ease;
            font-size: 1.8rem;
            &:hover {
                background-color: #dbdbdb;
            }
        }
    }
}
.pullQuote {
    border-left: 1px solid black; 
    margin: 30px 0 30px 30px;
    h1 {
        padding-left: 50px;
        margin: -20px 0;
        @include media-breakpoint-down(xs) {
            margin: 0;
            padding: 0;
        }
    }
}
.basicQuote { 
    color: black; 
    h1 {
        line-height: 1.5;
    }
}