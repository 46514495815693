.cards {
    overflow-y: scroll;
    text-align: center;
    background-color: $offwhite;
    padding: 50px 100px;
    height: 100%;
    @include media-breakpoint-down(xs) {
        padding: 50px 15px;
    }
    .scroll {
        width: 20px; 
        height: 35px; 
        border-radius: 15px; 
        border: 2px solid grey; 
        position: absolute; 
        margin-left: -40px; 
        top: 100px;
        .wheel {
            width: 3px;
            height: 7px;
            background-color: grey;
            margin-left: .43em;
            margin-top: 3px;
            border-radius: 100px;
        }
    }
    h3 {
        color: $light-grey;
    }
}

.card {
    min-width: 100%;
    margin-bottom: 50px;
    text-align: left;
    border: none;
    border-radius: none;
    @extend %subtle-dropshadow;
    .content {
        padding: 80px 60px;
        @media (max-width: 768px) { 
            padding: 40px 20px;
        }
    }
    a {
        color: $dark-grey;
    }
    .line {
        background-color: $dark-grey;
    }
    .image {
        width: 100%;
        height: 300px;
        @extend %standard-bg-image;
    }
}

#singleRow {
    @include media-breakpoint-down(lg) {
        height: unset!important;
    }
}