.navbar {
    z-index: 999999;
    background-color: black;
    height: 180px;
    @include media-breakpoint-down(xs) {
        height: 110px;
    }
    .navbar-toggler {
        margin-top: 80px;
        //background-color: white;
        @include media-breakpoint-down(xs) {
            margin-top: 0;
        }
        .navbar-toggler-icon {
            .menu-text {
                color: white; 
                position: absolute; 
                right: 80px; 
                bottom: 45px;
                transition: bottom .1s;
                @include media-breakpoint-down(xs) {
                    bottom: 24px;
                }
            }
            .nav-line {
                background-color: white;
                height: 2px;
                margin: 8px 0;
            }
        }
        &.collapsed {
            .navbar-toggler-icon {
                .menu-text {
                    bottom: 22px;
                    @include media-breakpoint-down(xs) {
                        bottom: 24px;
                    }
                }
            }
        }
    }
    .navbar-brand {
        background-image: url('/assets/development/logo_white-500.png');
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        width: 125px;
        height: 104.5px;
        @include media-breakpoint-down(xs) {
            width: 80px;
            background-size: contain;
        }
    }
    .subnav {
        position: absolute;
        top: 0;
        right: 20px;
        padding-top: 20px;
        height: 100px;
        @include media-breakpoint-down(xs) {
            display: none;
        }
        p {
            color: white;
        }
        .phone {
            padding-right: 20px;
        }
    }
    .dropdown-menu {
        border: 0;
        border-radius: 0;
        margin-top: 0;
        margin-left: -25px;
        background-color: $dark-grey;
        @include media-breakpoint-down(md) {
            margin-left: 0;
        }
    }
    ul.dropdown-menu.children.show {
        background-color: $dark-grey;
    }
    .nav {
        position: absolute;
        right: 0;
        bottom: 0;
        background-color: $dark-grey;
        padding: 0 45px;
        @include media-breakpoint-down(md) {
            position: relative;
            right: unset;
            bottom: unset;
            margin-top: 52px;
        }
        @include media-breakpoint-down(xs) {
            margin-top: -3px;
        }
        a {
            color: white;
            background-color: $dark-grey;
            &:hover {
                color: white;
            }
        }
        .nav-item {
            padding: 20px 10px;
            transition: border .3s ease;
            background-color: $dark-grey;
            @include media-breakpoint-down(md) {
                text-align: center;
                padding: 0;
            }
            &:hover {
                padding: 15px 10px;
                border-top: 5px solid #EDE7DF;
                @include media-breakpoint-down(md) {
                    border: 0;
                    padding: 0;
                }
            }
            a {
                @include media-breakpoint-down(md) {
                    padding: 20px 0;
                }
            }
        }
    }
    .children {
        .nav-item {
            padding: 0 10px;
            &:hover {
                padding: 0px 10px;
                border: 0;
                border-left: 2px solid white;
            }
            .dropdown-item {
                &:hover {
                    background-color: $dark-grey;
                }
            }
        }
    }
    .navbar-collapse {
        z-index: 99999;
    }
}

// .dropdown .dropdown-menu {
//     display: none;
// }

// .dropdown:hover .dropdown-menu {
//     display: block;
// }