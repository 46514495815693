.image-size-cover {
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.image-size-contain,
.image-size-contain-top {
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    &.image-size-contain-top {
        background-position: center top;
    }
}

.half {
    width: 50%;
}

.spacer {
    padding: 50px 0;
}

.breadcrumbs {
    p {
        font-family: $heading-font;
        font-size: 1.3rem;
    }

    .active {
        color: $primary-color;
    }
}

.padding-50 {
    padding: 50px 0;
}

.offwhite {
    background-color: $offwhite;
}

.matrix-blocks {
    padding-top: 100px;
}

.featherlight {
    .featherlight-content {
        padding: 0;
        border-bottom: 0;
    }
}

.dropdown-item:hover,
.dropdown-item:focus {
    background-color: unset;
}


.hidden {
    display: none;
}

.p-100 {
    padding: 30px;

    @include media-breakpoint-up(sm) {
        padding: 100px;
    }
}
