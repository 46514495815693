/* Color Variables */

$primary-color: #944F45;
$dark-grey: #353535;
$heading-font-color: #000000;
$body-font-color: rgb(221, 221, 221);
$light-grey: #646464;
$offwhite: #fafafa;

/* Font */
$heading-font: 'Playfair Display';
$body-font: 'Lora';

$divider: 1px solid rgba($color: #000000, $alpha: .2);