/* Text Styles */

h1 {
 font-size: 2.3rem;
 font-family: $heading-font;
 @include media-breakpoint-down(xs) {
     font-size: 1.5rem;
 }
}

h2 {
    margin-top: -30px;
    font-size: 1.5rem;
    font-family: $heading-font;
    padding-bottom: 30px;
    color: $light-grey;
}

h3 {
    font-size: 1.7rem;
    font-family: $heading-font;
    padding-bottom: 10px;
    // color: $light-grey;
}

h4 {
    color: $primary-color;
    font-size: 1.4rem;
    font-family: $heading-font;
}

p {
    font-family: $body-font;
    font-size: 1rem;
}

.font-white {
    p {
        color: $body-font-color;
    }
}

.title {
    h1 {
        @media (max-width: 768px) { 
            text-align: center;
        }
    }
}