.half-text {
    background-color: $offwhite;
    padding: 100px 150px;
    @include media-breakpoint-down(md) {
        padding: 50px;
    }
    @include media-breakpoint-down(sm) {
        padding: 30px 10px;
    }
    h1 {
        padding-bottom: 60px;
    }
}

.half-image {
    min-height: 700px;
    @include media-breakpoint-down(md) {
        height: 500px;
    }
}

#textImageSection {
    //margin-bottom: -100px;
}