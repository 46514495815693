.blocks {
    .block {
        @extend %standard-bg-image;
        height: 450px;
        color: white;
        a {
            text-decoration: none;
            h3 {
                color: white;
                text-decoration: none;
            }
        }
        .cta {
            margin-top: 200px;
            margin-left: 0px;
            opacity: 0;
            visibility: hidden;
            transition: all .3s ease;
            a {
                font-size: 20px;
                color: white;
                z-index: 100;
            }
            .line {
                background-color: white;
                z-index: 100;
            }
        }
        &.hover {
            .cta {
                margin-left: 10px;
                opacity: 1;
                visibility: visible;
            }
        }
        }
        h3 {
            z-index: 100;
            padding-bottom: 50px;
            position: relative;
        }
        .block-content {
            padding: 50px 20px;
        }
        .overlay {
            z-index: 10;
            background: radial-gradient(rgba(0,0,0,0), rgba(0,0,0,.6));
            position: absolute;
            width: 100%;
            height: 450px;
            margin-left: -15px;
            margin-right: -15px;
        }
        .red-bg {
            background-color: $primary-color;
        }
    }

.about {
    padding: 100px 0;
    .about-image {
        @media (max-width: 500px) { 
            width: 95%;
         }
        margin-top: 30px;
        width: 467px;
        height: 358px;
        @extend %standard-bg-image;
    }
    .about-image-subtitle {
        padding-top: 30px;
    }
    h1 {
        padding-bottom: 20px;
    }
    p {
        padding-bottom: 20px;
    }
    a {
        color: black;
    }
    .line {
        background-color: black;
    }
}

.featured-collections {
    background-color: $primary-color;
    position: relative;
    .home-collections-carousel {
        overflow: hidden;
    }
    .carousel-cell {
        width: 30%;
        margin-left: 1.5%;
        margin-right: 1.5%;
        margin-top: 150px;
        transition: margin-top .8s ease;
        @include media-breakpoint-down(lg) { 
            width: 100%;
            margin-left: 0;
            margin-right: 0;
            transition: none;
         }
        .card {
            min-width: 30%;
            background-color: rgba($color: #ffffff, $alpha: 0);
            transition: background-color .2s ease;
            box-shadow: 0px 13px 21px -10px rgba(0,0,0,0.0);
            position: relative;
            @include media-breakpoint-down(lg) { 
                transition: none;
             }
        }
        .image {
            height: 372px;
            width: 100%;
            @extend %standard-bg-image;
            .card-overlay {
                background-color: rgba($color: #000000, $alpha: .3);
                height: 100%;
                width: 100%;
            }
        }
        .content {
            opacity: 0;
        }
    }
    .is-selected {
        margin-top: 0;
        z-index: 5;
        .card-overlay {
            opacity: 0;
        }
        .content {
            opacity: 1;
        }
        .card {
            background-color: rgba($color: #ffffff, $alpha: 1.0);
            box-shadow: 0px 13px 21px -10px rgba(0,0,0,0.1);
        }
    }
    .flickity-slider {
        width: 100%;
    }
    .flickity-prev-next-button {
        top: 35%;
        @include media-breakpoint-down(lg) { 
            display: none;
         }
        &.next {
            right: 200px;
        }
        &.previous {
            left: 200px;
        }
    }
    .flickity-button-icon {
        // width: 500px;
        height: 100px;
        width: 100px;
    }
    
}

.title {
    margin-bottom: 75px;
    padding-top: 100px;
    h1 {
        color: white;
        padding: 0 30px;
    }
    .line {
        background-color: white;
        opacity: 1;
        width: 100px;
    }
}

.events-classes {
    background-color: $offwhite;
    padding: 100px 0;
    h1 {
        color: $dark-grey;
    }
    .line {
        background-color: $dark-grey;
    }
}

.masonry {
    column-count: 2;
    column-gap: 2em;
    @media (max-width: 992px) { 
        column-count: 1;
        column-gap: 0;
     }
    .event-class-card {
        position: relative;
        display: inline-block;
        min-width: 400px;
        margin-bottom: 50px;
        text-align: left;
        border: none;
        border-radius: none;
        @extend %subtle-dropshadow;
        @media (max-width: 500px) { 
            min-width: 200px;
        }
        .event-class-date {
            position: absolute;
            right: 20px;
            top: 20px;
            z-index: 100;
            background-color: white;
            padding: 5px 30px;
            @extend %subtle-dropshadow;
            .month {
                font-size: 1.5rem;
                text-transform: uppercase;
                color: #E60000;
            }
        }
        .content {
            padding: 80px 60px;
            @media (max-width: 768px) { 
                padding: 40px 20px;
            }
        }
        a {
            color: $dark-grey;
        }
        .line {
            background-color: $dark-grey;
        }
        img {
            width: 100%;
        }
    }
}

