// Center vertically and/or horizontally - Apply to parent element
.flex-center-vh {
    display: flex;
    justify-content: center;
    align-items: center;
}

.flex-center-vh-col {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.flex-center-vh-row {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
}

.flex-center-horiz-col {
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.flex-center-vert-col {
    display: flex;
    align-items: center;
    flex-direction: column;
}

.flex-center-vert-row {
    display: flex;
    flex-direction: row;
    align-items: center;
}