.block-divider {
    margin: 30px 0;
    height: 8px; 
    width: 86px;
}

.line {
    height: 1px;
    background-color: $light-grey;
    opacity: .2;
    width: 100%;
}