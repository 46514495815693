.home-feature {
    height: 700px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-position-y: 30%;
    @include media-breakpoint-down(xs) {
        height: 500px;
    }
    .overlay {
        z-index: 0;
        background: radial-gradient(rgba(0,0,0,0), rgba(0,0,0,.6));
        position: absolute;
        width: 100%;
        height: 100%;
        @include media-breakpoint-down(xs) {
            background: radial-gradient(rgba(0,0,0,0), rgba(0,0,0,.9));
        }
    }
    .image-content {
        z-index: 1;
        padding-left: 100px;
        width: 470px;
        color: white;
        @media (max-width: 500px) { 
            width: auto;
            padding-left: 5px;
            padding-right: 5px;
            text-align: center;
         }
        h1 {
            font-size: 4rem;
            @include media-breakpoint-down(xs) {
                font-size: 3rem;
            }
        }
    }
}

.interior-feature {
    padding-bottom: 50px;
    .feature-content {
        height: 500px;
        position: absolute;
        color: white;
        z-index: 11;
        left: 200px;
        width: 500px;
        @include media-breakpoint-down(md) {
            width: auto;
            left: 0;
            margin: 0 150px;
        }
        @include media-breakpoint-down(xs) {
            width: auto;
            left: 0;
            margin: 0 20px;
        }
        h1 {
            color: $body-font-color;
            padding-bottom: 30px;
            font-size: 1.5rem;
            .title {
                color: white;
                font-size: 2.5rem;
                font-weight: bold;
                font-style: italic;
            }
        }
        a {
            color: white;
        }
        .line {
            background-color: white;
        }
    }
    .overlay {
        z-index: 10;
        background: radial-gradient(rgba(0,0,0,0), rgba(0,0,0,.6));
        position: absolute;
        width: 100%;
        height: 500px;
        @include media-breakpoint-down(xs) {
            background: radial-gradient(rgba(0,0,0,0), rgba(0,0,0,.9));
        }
    }
    .anchor-bar {
        background-color: $primary-color; 
        height: 80px;
        text-transform: uppercase;
        color: white;
        padding-left: 4%;
        .anchors {
            a {
                color: inherit;
                text-decoration: none;
            }
            p {
                padding-right: 40px;
            }
        }
    }
    .no-sections {
        height: 10px;
        background-color: $primary-color; 
        text-transform: uppercase;
        color: white;
        padding-left: 4%;
    }
    &.single {
        margin-bottom: 0;
        padding-bottom: 0;
    }
}

.flickity-button {
    z-index: 100;
    background: transparent;
    &:hover {
        background: transparent;
    }
    .flickity-button-icon {
        fill: white;
    }
    &.next {
        padding-right: 100px;
    }
    &.previous {
        padding-left: 100px;
    }
}