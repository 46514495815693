nav {
  form {
    .search-form-nav {
      background-color: $dark-grey;
      // height: 40px;
      // width: 250px;
      border-radius: 50px;
      padding-left: 20px;
      padding-top: 5px;
      margin-right: 20px;
  }
  .search-icon {
      color: white;
      font-size: 30px;
  }
    input {
      color: white;
      &::placeholder {
        color: white;
      }
    }
  }
}

.results {
  padding: 100px 0;
  a {
    color: $dark-grey;
    text-decoration: underline;
  }
}

.search {
  .btn-link {
    .fa-search {
      color: $light-grey;
    }
  }
}