.calendar {
    padding: 100px 0 50px 0;
    .color-code {
        p {
            margin: 10px;
            padding-bottom: 5px;
            border-bottom: 5px solid;
        }
        .kid-classes p {
            border-color: #F5B885;
        }
        .exhibitions p {
            border-color: #944F45;
        }
        .adult-classes p {
            border-color: #586BA7;
        }
        .events p {
            border-color: #944F45;
        }
    }
}

.event-date-img {
    @extend %standard-bg-image;
}

.calendar-list {
    .divider {
        padding-top: 50px;
        padding-bottom: 50px;
        border-bottom: $divider;
    }
    .event-class-date {
            position: absolute;
            right: 35px;
            top: 20px;
            z-index: 100;
            background-color: white;
            padding: 10px 20px 0px 20px;
            @extend %subtle-dropshadow;
            .month {
                font-size: 1.5rem;
                text-transform: uppercase;
                color: #E60000;
            }
    }
    img {
        width: 100%;
    }
}

.fc-event {
    border-radius: 0!important;
}