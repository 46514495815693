@font-face {
    font-family: 'Playfair Display';
    src: url('/assets/fonts/PlayfairDisplay-Regular.woff2') format('woff2'),
         url('/assets/fonts/PlayfairDisplay-Regular.woff') format('woff');
  }
  
  @font-face {
    font-family: 'Lora';
    src: url('/assets/fonts/Lora-Regular.woff') format('woff'),
         url('/assets/fonts/Lora-Regular.woff2') format('woff');
  }