/* Featured Thumbnail is typically a vertical image on left with text on right centered vertically. */

.featured-thumbnail {
    margin-top: 150px;
    .thumbnail {
        height: 480px;
        width: 320px;
    }
    p {
        width: 350px;
    }
}