.google-map {
    width: 100%;
    height: 800px;
    background-color: rgb(228, 228, 228);
    &.full-width {
        margin-top: 0;
        height: 500px;
    }
}

.directions {
    margin: 50px 0;
}

.map {
    padding: 0;
}