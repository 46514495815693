.nav.contact {
    display: block;
    padding-left: 50px;
    a {
        color: black;
    }
}

.contact {
    .half {
        padding: 100px;
    }
    h1 {
        padding: 30px 0 15px 0;
    }
}

.contact-info {
    padding-top: 500px;
    @include media-breakpoint-down(md) {
        padding-top: 50px;
     }
}

.form {
    .dropdown-toggle {
        border: none;
        background: none;
        padding: 0;
    }
}