.cta {
    margin-top: 20px;
    .line {
        width: 60px;
        height: 1px;
        margin-right: 15px;
        opacity: 1;
    }
    a {
        font-family: $body-font;
        text-transform: uppercase;
    }
}